import * as React from 'react';
import { useDialogState } from '../../hooks';
import { useIntervalFile } from '../../hooks/interval';
import {
  DateRange,
  IntervalFile,
  IntervalAggregate,
  IntervalFrequency,
  AggregateOptions,
  FrequencyOptions,
  IntervalStream,
} from '../../models';
import { makeStylesHook, materialColors } from '../../styles';
import { DateTuple } from '../../types';
import { BarStack, IntervalGraph, ColorInfo } from '../graphs';
import { Grid } from '../Grid';
import { Icon } from '../Icon';
import { Dialog } from '../Dialog';
import { Button } from '../Button';
import { TabDatum } from '../TabDatum';
import { formatters } from '../../utils';
import { Drawer } from '../Drawer';
import { Card } from '../Card';
import { DateTime } from 'luxon';
import { Typography } from '../Typography';
import { Select } from '../Select';
import { VictoryLegendProps } from 'victory';
import { DownloadLink } from '../DownloadLink';
import { DateSpanPicker } from '../Date';
import { Loading } from '../Loading';
import { Box } from '..';

/** ======================== Types ========================================= */
type IntervalFileBarProps = {
  id?: IntervalFile['id'];
  title?: string;
  bounds?: DateTuple;
  iconName?: 'sun' | 'battery' | 'plug' | 'plus';
  fetching?: boolean;
};

type SpanBarProps = {
  intervalFile: IntervalFile;
  bounds: DateTuple;
  title: string;
};

/** ======================== Styles ======================================== */
const useIntervalFileStyles = makeStylesHook((theme) => {
  return {
    iconContainer: theme.mixins.flex({ direction: 'column', align: 'center', justify: 'center' }),
    barPaper: { color: 'grey' },
    cardActions: { flexDirection: 'row-reverse' },
  };
}, 'IntervalFile');

/** ======================== Components ==================================== */

const SpanBar: React.FC<SpanBarProps> = ({ intervalFile, bounds, title }) => {
  const classes = useIntervalFileStyles();
  const [modalOpen, openModal, closeModal] = useDialogState();
  const [start, setStart] = React.useState<DateTime>(intervalFile.starts_at);
  const [end, setEnd] = React.useState<DateTime>(intervalFile.ends_at);
  const [agg, setAgg] = React.useState<IntervalAggregate>('sum');
  const [freq, setFreq] = React.useState<IntervalFrequency>('15Min');
  const [downloadLink, setDownloadLink] = React.useState<string>();

  const getDataWithAnomalies = () => {
    const { dateRange } = intervalFile;
    const spans = intervalFile.anomalies;
    if (!dateRange || !spans) return undefined;
    if (spans.ranges.length) {
      const inverse = spans.getInverse(dateRange);
      return [...inverse.ranges, ...spans.ranges];
    }
  };
  const dataWithAnomalies: DateRange[] = React.useMemo(getDataWithAnomalies, [intervalFile]) || [
    intervalFile.dateRange,
  ];

  let allData: DateRange[] = dataWithAnomalies
    .map((range: DateRange) => {
      const estInverse = intervalFile.estimates?.getInverse(range);
      let total: DateRange[] = [];
      if (estInverse && intervalFile.estimates?.ranges.length) {
        total = [...intervalFile.estimates.filterByInterval(range), ...estInverse.ranges];
      }
      if (total.length) return total;
      return [range];
    })
    .flat();

  const legendData: VictoryLegendProps['data'] = [
    { name: 'Original Data', symbol: { fill: materialColors['green'][200] } },
    { name: 'Estimated Data', symbol: { fill: materialColors['orange'][200] } },
    { name: 'Gaps', symbol: { fill: materialColors['grey'][100] } },
    { name: 'Unexpected Zeros', symbol: { fill: materialColors['grey'][900] } },
    { name: 'Negative Values', symbol: { fill: materialColors['red'][900] } },
    { name: 'Impossibly High Values', symbol: { fill: materialColors['amber'][200] } },
    { name: 'Delayed Reporting', symbol: { fill: materialColors['purple'][200] } },
    { name: 'Far Above Baseline', symbol: { fill: materialColors['brown'][300] } },
    { name: 'Bill kWh Data Mismatch', symbol: { fill: materialColors['brown'][700] } },
  ];

  const datesDidChange = React.useCallback(
    (s, e) => {
      setStart(s);
      setEnd(e);
    },
    [setStart, setEnd]
  );

  return (
    <>
      <BarStack
        bounds={bounds}
        colorFn={barColors}
        data={allData}
        showLabels={false}
        onClick={openModal}
        hoverAll={true}
        showToolTips={false}
      />
      <Drawer
        open={modalOpen}
        onClose={() => {
          closeModal();
          setDownloadLink(undefined);
        }}
        anchor="bottom"
      >
        <Dialog.Title>{title}</Dialog.Title>
        <Dialog.Content>
          <Grid>
            <Grid.Item span={12}>
              <Box padding={2} paddingTop={4}>
                <BarStack
                  bounds={bounds}
                  colorFn={barColors}
                  data={allData}
                  showLabels={false}
                  legendData={legendData}
                />
              </Box>
            </Grid.Item>
            <Grid.Item span={6}>
              <Card>
                <Card.Content>
                  <Typography>General Info</Typography>
                  <TabDatum field="Minimum" value={intervalFile.min_value} />
                  <TabDatum field="Maximum" value={intervalFile.max_value} />
                  <TabDatum field="Average" value={intervalFile.average_value} />
                  <TabDatum
                    field="Start"
                    value={formatters.date.monthDayYear(intervalFile.starts_at)}
                  />
                  <TabDatum
                    field="End"
                    value={formatters.date.monthDayYear(intervalFile.ends_at)}
                  />
                </Card.Content>
              </Card>
            </Grid.Item>
            <Grid.Item span={6}>
              <Card>
                <Card.Content>
                  <Typography>Configuration</Typography>
                  <Grid>
                    <Grid.Item span={12}>
                      <DateSpanPicker
                        disabled={false}
                        datesDidChange={datesDidChange}
                        defaultStartDate={intervalFile.starts_at}
                        defaultEndDate={intervalFile.ends_at}
                      />
                    </Grid.Item>
                    <Grid.Item span={6}>
                      <Select
                        label="Aggregation Method"
                        fullWidth
                        onChange={(value) => {
                          setAgg(value);
                        }}
                        options={AggregateOptions.map((val) => val)}
                        value={agg}
                      />
                    </Grid.Item>
                    <Grid.Item span={6}>
                      <Select
                        label="Frequency"
                        fullWidth
                        onChange={(value) => {
                          setFreq(value);
                        }}
                        options={FrequencyOptions.map((val) => val)}
                        value={freq}
                      />
                    </Grid.Item>
                  </Grid>
                </Card.Content>
                <Card.Actions className={classes.cardActions}>
                  <Button
                    onClick={() => {
                      const params = {
                        start: start.toISODate(),
                        end: end.toISODate(),
                        agg,
                        freq,
                      };
                      const response = IntervalFile.api.intervals_csv(intervalFile.id, params);
                      response.then((result) => {
                        const { file } = result;
                        setDownloadLink(file.url);
                      });
                    }}
                    color="primary"
                  >
                    Download CSV
                  </Button>
                  <DownloadLink url={downloadLink} />
                </Card.Actions>
              </Card>
            </Grid.Item>
            <Grid.Item span={12}>
              <IntervalGraph
                id={intervalFile.id}
                title=""
                streams={[
                  {
                    name: title,
                    color: 'green',
                    key: 1,
                    dataFn: graphDataStream,
                  },
                ]}
              />
            </Grid.Item>
          </Grid>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onClick={closeModal}>Close</Button>
        </Dialog.Actions>
      </Drawer>
    </>
  );

  /** ======================== Callbacks =================================== */
  function graphDataStream(stream: IntervalStream) {
    return stream;
  }

  /** ====================== Helpers ======================================= */
  function barColors(span: DateRange): ColorInfo {
    if (intervalFile.unexpected_zeros?.ranges.includes(span)) {
      return { color: 'grey', name: 'Unexpected Zeroes', shade: 900 };
    }
    if (intervalFile.negative_values?.ranges.includes(span)) {
      return { color: 'red', name: 'Negative Value', shade: 900 };
    }
    if (intervalFile.impossible_high_values?.ranges.includes(span)) {
      return { color: 'amber', name: 'Impossibly High Value', shade: 200 };
    }
    if (intervalFile.delayed_reporting?.ranges.includes(span)) {
      return { color: 'purple', name: 'Delayed Reporting', shade: 200 };
    }
    if (intervalFile.far_above_baselines?.ranges.includes(span)) {
      return { color: 'brown', name: 'Far Above Baseline', shade: 300 };
    }
    if (intervalFile.bill_kwh_mismatch?.ranges.includes(span)) {
      return { color: 'brown', name: 'Bill kWh Data Mismatch', shade: 700 };
    }
    if (intervalFile.estimates?.ranges.includes(span)) {
      return { color: 'orange', name: 'Estimated Data', shade: 200 };
    }
    if (intervalFile.gaps?.ranges.includes(span)) {
      return { color: 'grey', name: 'Gap', shade: 100 };
    }
    return { color: 'green', name: 'Original Data', shade: 200 };
  }
};

export function IntervalFileBar(props: IntervalFileBarProps) {
  const classes = useIntervalFileStyles();
  const { id, iconName, fetching = false, title = 'Intervals' } = props;
  let { bounds } = props;

  const { loading, intervalFile } = useIntervalFile(id);
  if (!bounds) bounds = [intervalFile?.starts_at! as DateTime, intervalFile?.ends_at! as DateTime];
  if (!intervalFile && !fetching) return null;

  return (
    <Grid>
      {iconName && (
        <Grid.Item className={classes.iconContainer} span={1}>
          <Icon name={iconName} />
        </Grid.Item>
      )}
      <Grid.Item span={iconName ? 11 : 12}>
        {((loading && !intervalFile) || fetching) && <Loading />}
        {!loading && intervalFile && !fetching && (
          <SpanBar intervalFile={intervalFile} bounds={bounds} title={title} />
        )}
      </Grid.Item>
    </Grid>
  );
}
